import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'

class FortWorthTX extends React.Component {
	render() {
		const siteTitle =
			'Fort Worth Gate Repair & Installation - J&J Gates Service and Design'
		const siteDescription =
			'We Offer Professional Gate Design and Installation in Fort Worth, TX. Call now at  to Find Out More About Our Gate Services and Free Estimates.'

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />

				<div className="container-fluid text-center headline gates-headline">
					<div className="container">
						<h1 className="text-white m-0">
							Fort Worth Gate Repair & Installation Service
						</h1>
						<hr />
					</div>
				</div>
				<div className="container gates-container">
					<div className="row">
						<div className="col-md-12">
							<h4>Our Fort Worth Location</h4>
							<p className="font-weight-bold">
								J & J Gates Service And Design 201 Main Street 6TH Floor Fort
								Worth, TX 76102 <br />
								<a href="tel:8174662794">(817)-466-2794</a>
							</p>
							<hr />
							<p>
								At J&J Gates Service and Design, we install custom gates in Fort
								Worth, TX for both residential and commercial properties. Should
								you have a gate installed on your property? These are some of
								the benefits of automatic gates:
							</p>
							<ul>
								<li>
									<i className="fas fa-dungeon mx-2 " />
									<strong>Increase your property value</strong> - Not every
									house has a security gate. In fact, few do. You’ll get some
									nice equity and a better resale value if you ever want to sell
									and move.
								</li>
								<li>
									<i className="fas fa-dungeon mx-2 " />
									<strong>More privacy</strong> - Automatic driveway gates can
									have poles which allow you and visitors to easily see through.
									But, you can also get solid ones to increase your privacy.
								</li>
								<li>
									<i className="fas fa-dungeon mx-2 " />
									<strong>Much more security</strong> - How did you stop people
									from walking on your lawn in the past? With a security gate,
									no one can just walk onto or enter your property on a whim.
								</li>
								<li>
									<i className="fas fa-dungeon mx-2 " />
									<strong>Little maintenance</strong> - Out of all the things
									you do to make your home look nice, security gates require
									some of the least maintenance. If you have mechanical
									knowledge, moving parts should be lubricated. If you don’t,
									you should at least clean the gate itself with household
									cleaners. You can always hire a gate repair professional to do
									the work for you.
								</li>
							</ul>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<h5>Why Hire J&J to Install or Repair Your Automatic Gate?</h5>
							<p>
								For starters, other companies perform the same service as we do,
								but usually, they contract the work to us. That’s because
								automatic driveway gate repair and installation is all we do! We
								also have an A+ rating from the BBB you can check{' '}
								<a
									href="https://www.bbb.org/us/tx/arlington/profile/door-and-gate-operating-devices/j-j-gates-service-and-design-0825-235965056"
									target="_blank"
									rel="noopener noreferrer"
								>
									here
								</a>{' '}
								for yourself. It’s our goal to make sure you get the perfect
								security gate style for your home and property. And most
								installations are finished in a week or two. Personal service?
								Proven track-record? Speedy installation? Check. Check. And
								check.
							</p>
							<p>
								And make sure you talk to us in person. You’ll love our
								friendly, down-to-earth approach. You’ll realize we can walk you
								through the entire gate installation process so you get a gate
								that amazes you and your neighbors. And you’ll love the fact
								that you don’t face any intense sales pressure.
							</p>
							<p>
								Just call <a href="tel:8174662794">(817)-466-2794</a> or{' '}
								<Link to="/contact-us">contact us online</Link> to get your free
								quote today.
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<h4>Got Gate Questions? We Have Answers!</h4>
							<h5>1.What if the power goes out? How will I open my gate?</h5>
							<p>
								Good question. Don’t worry because you’re not stuck inside or
								outside your property. Your gate can be installed with backup
								battery power for situations like this. Other means of an
								emergency bypass, like a manual opening, can also be installed.
								It depends on your preferences and budget.
							</p>
							<h5>
								2. What do most Fort Worth homeowners overlook when they
								consider their gate?
							</h5>
							<p>
								Much of it revolves around how other people will gain access to
								your property. For example, what if you need a delivery done,
								someone to do a repair on your home or property, or a monthly
								meter reading from the utility company?
							</p>
							<p>
								Don’t forget, you may also get a reduction in your homeowner’s
								insurance with your gate, depending on the company and your
								policy. And you may also have to pay some additional insurance
								premiums for coverage to damage on your automatic driveway gate.
							</p>
							<h5>3. How long does it take my gate to open?</h5>
							<p>
								If it slides, you’re looking at about 1 foot per second, times
								the length of your sliding gate (10 – 20 feet usually). If it
								swings open, that’ll be much faster and around just a few
								seconds or so.
							</p>
							<h5>4. How much maintenance do I have to do?</h5>
							<p>
								Not a ton, but automatic driveway gates do have a little
								maintenance. Some of it involves lubricating the parts so they
								don’t get damaged and worn as easily. Electronics need to be
								kept clear of pests and their nests, and spider webs.
							</p>
							<p>
								Higher-end gates actually require lesser maintenance than
								cheaper ones. They can have sealed bearing hinges, which are
								maintenance-free. However, even these gates need at least a
								yearly look from a professional.
							</p>
							<h4>Need help deciding?</h4>
							<p>
								Have more questions on driveway gates? Call our friendly
								professionals at
								<a href="tel:8174662794">(817)-466-2794</a> or{' '}
								<Link to="/contact-us">contact us online</Link>.{' '}
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<iframe
								style={{ border: 0 }}
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d858972.0930768313!2d-97.89224132249751!3d32.75618788562148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e7140c232202b%3A0xb12a5190509384c6!2sJ%26J+Gate+Service+%26+Design!5e0!3m2!1sen!2sus!4v1450713459061"
								allowFullScreen="allowfullscreen"
								width="100%"
								height={450}
								frameBorder={0}
								title="Fort Worth TX"
							/>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default FortWorthTX

export const FortWorthTXPageQuery = graphql`
	query FortWorthTXPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
